import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CopyRight from "../../components/CopyRight";
import { CButton, CCol, CContainer, CRow } from "@coreui/react";
import { CModal, CModalBody } from "@coreui/react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import BlogSlider from "../../components/blogs/BlogSlider";
import Spinner from "../../components/loader/Spinner";
import InstanceBlogs from "../../components/blogs/InstanceBlogs";
import { Helmet } from "react-helmet";

//--- images
import shareIcon from "../../assets/images/share-icon.svg";
import quoteI from "../../assets/images/blogs/quote.png";

import linkedInI from "../../assets/images/social-icons/linkedIn.svg";
import fbI from "../../assets/images/social-icons/fb.svg";
import twitterI from "../../assets/images/social-icons/twitter.svg";
import discordI from "../../assets/images/social-icons/discord.svg";
import whatsappI from "../../assets/images/social-icons/whatsapp.svg";
import copyI from "../../assets/images/social-icons/copy-icon.svg";

function BlogDetails() {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { blogId } = useParams();
  const [alertTxt, setAlertTxt] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [blogDetails, setBlogDetails] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const imgBaseUrl = process.env.REACT_APP_IMAGE_URL;
  const shareUrl = process.env.REACT_APP_SHARE_URL;

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
    handleScrollToTop();
  }, []);

  const fetchBlogDetails = async () => {
    setLoading(true);
    try {
      const language = localStorage.getItem("language");
      const response = await fetch(`${apiUrl}/blogs/${slug}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "accept-language": language,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setBlogDetails(data.data.blog);
        setGalleryImages(data.data.galleryImages || []);
        setSliderImages(data.data.sliderImages || []);
        setRecentBlogs(data.data.recentBlogs);
        setRelatedBlogs(data.data.relatedBlogs);
      } else {
        console.error("Failed to fetch blog details:", data.message);
      }
    } catch (error) {
      console.error("Error fetching blog details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlogDetails();
}, [slug]); 
  useEffect(() => {
    const adjustImages = () => {
      const images = document.querySelectorAll(".blog-txt-details img");
      images.forEach((img) => {
        // Wait for the image to load to get its natural width
        img.onload = () => {
          if (img.naturalWidth > 855) {
            img.style.width = "100%";
            img.style.height = "100%";
            img.style.objectFit = "contain";
          }
        };
      });
    };

    adjustImages();
  }, [blogDetails]);

  useEffect(() => {
    const adjustVideos = () => {
      const videos = document.querySelectorAll(".blog-txt-details video");
  
      videos.forEach((video) => {
        video.addEventListener("loadedmetadata", () => {
          if (video.videoWidth > 855) {
            video.classList.add("large-video");
          }
        });
      });
    };
  
    adjustVideos();
  }, [blogDetails]);
  

  return (
    <>
      <Helmet>
        {/* Dynamic Title and Description */}
        <title>{`${blogDetails?.title} | Blog by ${blogDetails?.author} | Cinewoo`}</title>
        <meta
          name="description"
          content={`Read insightful articles on film, talent, and industry trends on Cinewoo.`}
        />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content={`${blogDetails?.title} | Blog by ${blogDetails?.author}`}
        />
        <meta
          property="og:description"
          content={"Explore engaging content on Cinewoo's blog."}
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/blog-detail/${slug}`}
        />
        <meta property="og:image" content={"https://cinewoo.com/logo.png"} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Cinewoo" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${blogDetails?.title} | Blog by ${blogDetails?.author}`}
        />
        <meta
          name="twitter:description"
          content={"Explore engaging content on Cinewoo's blog."}
        />
        <meta name="twitter:image" content={"https://cinewoo.com/logo.png"} />
        <meta
          name="twitter:url"
          content={`${window.location.origin}/blog-detail/${slug}`}
        />

        {/* Article Meta Tags */}
        <meta property="article:author" content={blogDetails?.author} />
        <meta
          property="article:published_time"
          content={blogDetails?.createdAt}
        />
      </Helmet>
      <Navbar authorized={false} classTitle="blog-nav" />

      <section className="blog-detail-page-wrapper">
        <CContainer>
          <CRow>
            {/* <CCol lg={12} className='d-lg-block d-none'>
                            <div className='bread-crumbs pb-4'>
                                <p>
                                    <Link to='/'>Home</Link> /
                                    <Link to='/blogs'>Blog</Link> /
                                    <Link to='/blogs'>{blogDetails?.title}</Link>
                                </p>
                            </div>
                        </CCol> */}

            <CCol lg={8}>
              {loading ? (
                <Spinner />
              ) : (
                <div className="blog-details-content">
                  {blogDetails && (
                    <>

                    <CRow className="blog-title mb-4 ">
                      <CCol lg={9}>
                      <div>
                          <h2>{blogDetails.title}</h2>
                          <p>
                            Published on:{" "}
                            <span className="me-2">
                              {blogDetails?.createdAt &&
                                new Date(
                                  blogDetails.createdAt
                                ).toLocaleDateString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  year: "numeric",
                                })}
                            </span>
                            {/* | <span className='ms-2'>23 min read</span> */}
                          </p>
                        </div>

                      </CCol>
                      <CCol lg={3} className="d-flex justify-content-end">
                      <CButton
                            className="share-btn"
                            onClick={() => setVisible(true)}
                            style={{ position: "inherit", width: "150px"}}
                          >
                            <img src={shareIcon} alt="icon" className="me-1" />
                            <span>{t("Share")}</span>
                          </CButton>
                    

                      </CCol>
                    </CRow>
                    
                      {/* Blog Slider */}
                      {/* Blog Slider - Only Show If sliderImages Exists */}
                      {sliderImages.length > 0 && (
                        <BlogSlider sliderImages={sliderImages} />
                      )}

                      {/* <div
                        className="blog-txt-details mt-5"
                        dangerouslySetInnerHTML={{
                          __html: blogDetails.content,
                        }}
                      /> */}

                      <div
                        className="blog-txt-details mt-5"
                        dangerouslySetInnerHTML={{
                          __html: blogDetails.content.replace(
                            /<img /g,
                            `<img style="max-width: 100%; max-height: 100%; object-fit: contain;"`
                          ),
                        }}
                      />

                      <div className="blog-gallery mt-5">
                        <CRow>
                          {galleryImages.map((image, index) => {
                            // Determine column width based on the repeating pattern
                            const columnPattern = [8, 4, 4, 8, 8, 4, 4, 8]; // Define the repeating pattern
                            const columnSize =
                              columnPattern[index % columnPattern.length]; // Cycle through the pattern

                            return (
                              <CCol
                                lg={columnSize}
                                className="mb-4"
                                key={image._id}
                              >
                                <div className="blog-gallery-img">
                                  <img
                                    src={`${imgBaseUrl}/${image.image}`}
                                    alt={`gallery-${index}`}
                                  />
                                </div>
                              </CCol>
                            );
                          })}
                        </CRow>
                      </div>
                    </>
                  )}
                </div>
              )}
            </CCol>
            <CCol lg={4} className="sticky-sidebar">
  <InstanceBlogs
    recentBlogs={recentBlogs}
    relatedBlogs={relatedBlogs}
  />
</CCol>
          </CRow>
        </CContainer>
      </section>

      <Footer />
      <CopyRight />

      {/*/--- Share Modal ---/*/}
      <CModal
        className="photos-upload-modal"
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalBody>
          <div className="upload-photo-modal-body-content film-upload-content py-3 px-4">
            <h3 className="text-start">{t("Share this blog")}</h3>
            <div className="social-platforms d-flex align-items-center justify-content-center mt-4">
              {/* LinkedIn */}
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                  `${shareUrl}/blog-detail/${slug}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedInI} alt="LinkedIn" />
              </a>

              {/* Facebook */}
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  `${shareUrl}/blog-detail/${slug}`
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-3"
              >
                <img src={fbI} alt="Facebook" />
              </a>

              {/* Twitter */}
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  `${shareUrl}/blog-detail/${slug}`
                )}&text=${encodeURIComponent("Check out this blog!")}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterI} alt="Twitter" />
              </a>

              {/* Discord */}
              {/* <a
                        href={`https://discord.com/channels/@me`} // Discord doesn't have direct sharing, so adjust as needed
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={discordI} alt='Discord' />
                    </a> */}

              {/* WhatsApp */}
              {/* <a
                        href={`https://wa.me/?text=${encodeURIComponent(`Check out this blog: ${shareUrl}/blog-detail/${blogId}`)}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={whatsappI} alt='WhatsApp' />
                    </a> */}
            </div>

            <div className="copy-url-wrapper mt-5 position-relative">
              <input
                type="text"
                defaultValue={`${shareUrl}/blog-detail/${slug}`}
                placeholder="Blog URL"
                readOnly
              />
              <Link
                to="#"
                onClick={() => {
                  const link = `${shareUrl}/blog-detail/${slug}`;
                  navigator.clipboard
                    .writeText(link)
                    .then(() => {
                      setAlertTxt("Link Copied!");
                      setTimeout(() => setAlertTxt(""), 3000);
                    })
                    .catch((err) => {
                      console.error("Failed to copy the link: ", err);
                      setAlertTxt("Failed to copy!");
                      setTimeout(() => setAlertTxt(""), 3000);
                    });
                }}
              >
                <img src={copyI} alt="icon" />
              </Link>
              <span>{alertTxt}</span>
            </div>
          </div>
          <CButton
            className="close_modal_btn"
            onClick={() => setVisible(false)}
          ></CButton>
        </CModalBody>
      </CModal>
    </>
  );
}

export default BlogDetails;
