import React from 'react';
import defaultImage from '../../assets/images/blogs/no-image.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function ReleventBlogCard({ blog }) {

    const { t } = useTranslation();


    const imageUrl = blog?.featureImage 
    ? `${process.env.REACT_APP_IMAGE_URL}/${blog.featureImage}` 
    : defaultImage;


    return (
        <>
            <div className='relevent-blog-card-wrapper'>
                <div className='relevent-blog-img'>
                <Link to={`/blog-detail/${blog.slug}`}>
                        <img src={imageUrl} alt={blog?.title || 'Blog Image'} alt="article" />
                    </Link>
                </div>
                <div className='relevent-blog-details mt-2'>
                    <h3>
                        <Link to={`/blog-detail/${blog.slug}`}>
                            {blog.title}
                        </Link>
                    </h3>
                    <p>
  {t("Published on")}: 
  <span className='mx-1'>
    {blog?.createdAt &&
      new Date(blog.createdAt).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}
  </span>
  {/* | <span className='ms-2'>23 min read</span> */}
</p>

                </div>
            </div>
        </>
    )
}

export default ReleventBlogCard;