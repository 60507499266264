import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function BlogsCard({blogImg, blogWriter, blogPostDate, blogTitle, blogDetails, blogId, blogSlug }) {

    const { t } = useTranslation();

    const formattedDate = new Date(blogPostDate).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric", 
        year: "numeric", 
    });


    return (
        <>
            <div className='blog-card-wrapper'>
                <Link to={`/blog-detail/${blogSlug}`}>
                    <div className='blog-card-img'>
                        <img src={blogImg} alt='article'/>
                    </div>
                </Link>
                <div className='blog-card-content'>
                    <div className='blog-by-txt pt-3'>
                    <p>{t("By")} <span>{blogWriter}</span> | {formattedDate}</p>
                        <h2>
                            <Link to={`/blog-detail/${blogSlug}`}>{blogTitle}</Link>
                        </h2>
                    </div>
                    <div className='blog-details'>
                        <p>{blogDetails}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogsCard;