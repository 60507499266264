import React from 'react';
import { useState, useEffect } from 'react';
import { CCarousel, CCarouselItem } from '@coreui/react';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import SlideBanner from '../components/SlideBanner';
import { useTranslation } from 'react-i18next';

//--------- images
import slide1 from '../assets/images/banner-bg5.png';
import slide2 from '../assets/images/banner-bg3.png';
import slide3 from '../assets/images/banner-bg4.png';
import slide4 from '../assets/images/banner-bg2.png';
import { Helmet } from 'react-helmet';

function Home() {
    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "cinewoo – Empowering Canadian Film Talent",
        "description": "Discover cinewoo, the ultimate digital platform connecting Canadian filmmakers, actors, writers, and industry professionals. Our innovative features like the Online Casting Room and 60 Seconds Film Pitching Booth provide everything emerging talents need to get discovered and hired. cinewoo means cinema in Canada, celebrating and empowering the richness of Canadian cinema. Follow us for updates, industry insights, and exclusive film content.",
        "url": `${window.location.origin}/`,
        "publisher": {
          "@type": "Organization",
          "name": "Cinewoo",
          "url": "https://cinewoo.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://cinewoo.com/logo.png", // Replace with actual logo URL
            "width": 250,
            "height": 60
          }
        },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": `${window.location.origin}/`
            }
          ]
        }
      };
    const sliders = [
        { title: 'Milena Rodriges', imgSrc: 'by Elena Davis', btnContent: 'SignUp' },
    ]

    const { t } = useTranslation();
    
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem("token");
        setIsLoggedIn(!!token);
    }, []); 

    const language = localStorage.getItem("language");

    return (
        <>
         <Helmet>
         <title>cinewoo – Empowering Canadian Film Talent</title>
         <meta name="description" content="Discover cinewoo, the ultimate digital platform connecting Canadian filmmakers, actors, writers, and industry professionals. Our innovative features like the Online Casting Room and 60 Seconds Film Pitching Booth provide everything emerging talents need to get discovered and hired. cinewoo means cinema in Canada, celebrating and empowering the richness of Canadian cinema. Follow us for updates, industry insights, and exclusive film content."/>
        <meta property="og:title" content="cinewoo – Empowering Canadian Film Talent" />
        <meta
          property="og:description"
       content="Discover cinewoo, the ultimate digital platform connecting Canadian filmmakers, actors, writers, and industry professionals. Our innovative features like the Online Casting Room and 60 Seconds Film Pitching Booth provide everything emerging talents need to get discovered and hired. cinewoo means cinema in Canada, celebrating and empowering the richness of Canadian cinema. Follow us for updates, industry insights, and exclusive film content."
        />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
            {/*/--- navbar ---/*/}
            <Navbar />

            <div className='home-page-wrapper w-100'>
                {sliders.length <= 0?
                    <span>No Slides are Created!</span>
                :
                    <CCarousel interval={8000} pause={false}>
                            <CCarouselItem>
                                <SlideBanner 
                                    title={t('home.title1')}
                                    title2={t('home.title2')}
                                    title3={t('home.title3')}
                                    bannerClass='banner-layer2'
                                    bnnerImg={slide1}
                                    btnContent={t('home.btn.signup')}
                                    bannerSignUp={`banner-signup-btn ${isLoggedIn ? 'd-none' : ''} ${language === 'fr' ? 'tanslated-btn' : ''}`}
                                    link='/signup'
                                />
                            </CCarouselItem>
                            <CCarouselItem>
                                <SlideBanner 
                                    title={t('home.slide2.title')}
                                    title2={t('home.slide2.title2')}
                                    subtitle={t('home.slide2.subtitle')}
                                    bannerClass='banner-layer2'
                                    bnnerImg={slide3}
                                    btnContent={t('home.btn.explore')}
                                    link='/casting-room'
                                    bannerSignUp={`banner-explore-btn`}
                                />
                            </CCarouselItem>
                            <CCarouselItem>
                                <SlideBanner 
                                    title={t('home.slide3.title')}
                                    title2={t('home.slide3.title2')}
                                    subtitle={t('home.slide3.subtitle')}
                                    bannerClass='banner-layer'
                                    bnnerImg={slide2}
                                    btnContent={t('home.btn.explore')}
                                    link='/film-pitching-booth'
                                    bannerSignUp={`banner-explore-btn`}
                                />
                            </CCarouselItem>

                            <CCarouselItem>
                                <SlideBanner 
                                    title={t('Join THE film community')}
                                    title2={t('subscribe now')}
                                    subtitle={t('Connect with Industry Professionals!')}
                                    bannerClass='banner-layer'
                                    bnnerImg={slide4}
                                    btnContent={t('home.btn.explore')}
                                    link='/subscription#subscription-wrapper'
                                    bannerSignUp={`banner-explore-btn`}
                                />
                            </CCarouselItem>
                            
                    </CCarousel>
                }
            </div>

            {/*/--- Footer ---/*/}
            <Footer />

            {/*/--- copy right ---/*/}
            <CopyRight />
        </>
    )
}

export default Home;
