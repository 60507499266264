import React, { useEffect, useState } from 'react';
import { CContainer, CRow, CCol, CButton } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { useNavigate } from 'react-router-dom';

//-------- components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import PackageCard from '../components/PackageCard';

function Subscription() {

    const [blocking, setBlocking] = useState(false);
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          if (location.hash === '#subscription-wrapper') {
            scrollToSection();
          } else {
            window.scrollTo(0, 0);
          }
        }, 100);
    
        return () => clearTimeout(timeoutId); 
    }, [location]);

    const scrollToSection = () => {
      const castingCallsSection = document.getElementById('subscription-wrapper');
      if (castingCallsSection) {
        castingCallsSection.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const handleNavigate = () => {
        navigate('/film-pitching-booth#profiles-wrapper');      
    }

    return (
        <>
            <BlockUi tag="div" blocking={blocking}>
                {/*/--- navbar ---/*/}
                <Navbar />

                {/*/--- banner section ---/*/}
                <section className='home-banner subscribe-banner'>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md={12}>
                                <div className='home-banner-wrapper text-center'>
                                    <h1>
                                        {t('Join THE film community')}<br/>
                                        {t('subscribe now')}
                                    </h1>
                                    <p>{t('Connect with Industry Professionals!')}</p>
                                    <CButton className='explore-btn mt-5' onClick={handleNavigate}>{t('Explore')}</CButton>
                                </div>
                            </CCol>
                        </CRow>
                    </CContainer>
                </section>
                

                {/*/--- banner section ---/*/}
                <section className='subscription-wrapper' id='subscription-wrapper'>
                    <CContainer className="h-100-p">
                        <CRow className="align-items-center h-100-p">
                            <CCol md={12}>
                                <div className='subscription-wrapper-content text-center'>
                                    <h2 className='section-heading text-center'>{t('START YOUR')}</h2>
                                    <h3 className='section-sub-heading text-center'>{t('MEMBERSHIP')}</h3>
                                    <p className='section-txt'>
                                        {t("Cinewoo: Empowering Canada's Film Community.")}<br/>
                                        {t('Our first 300 members will receive free VIP access to the platform for a full year–Subscribe today!')}
                                    </p>
                                </div>
                                <PackageCard durationTxt={t('Community Members')} time={t('Enjoy a Free Year')} subscribe={true} setBlocking={setBlocking} />
                            </CCol>
                        </CRow>
                    </CContainer>
                </section>

                {/*/--- Footer ---/*/}
                <Footer />

                {/*/--- copy right ---/*/}
                <CopyRight />
            </BlockUi>
        </>
    )
}

export default Subscription;
