import React from "react";
import { CContainer, CRow, CCol } from "@coreui/react";
import { useTranslation } from "react-i18next";

//-------- components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import { Helmet } from "react-helmet";

function VideoHub() {
  const { t } = useTranslation();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Video Hub | cinewoo",
    description:
      "A dynamic collection of behind-the-scenes footage, live shows, live streams, and talent video profiles, sharing personal stories and journeys—all promoting Canadian films and emerging talent.",
    url: `${window.location.origin}/video-hub`,
    publisher: {
      "@type": "Organization",
      name: "Cinewoo",
      url: "https://cinewoo.com",
      logo: {
        "@type": "ImageObject",
        url: "https://cinewoo.com/logo.png", // Replace with actual logo URL
        width: 250,
        height: 60,
      },
    },
    breadcrumb: {
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "video-hub",
          item: `${window.location.origin}/video-hub`,
        },
      ],
    },
  };

  return (
    <>
      {/* <Helmet>
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet> */}

      <Helmet>
        {/* Dynamic Title and Description */}
        <title>Video Hub | cinewoo</title>
        <meta
          name="description"
          content="A dynamic collection of behind-the-scenes footage, live shows, live streams, and talent video profiles, sharing personal stories and journeys—all promoting Canadian films and emerging talent."
        />
 <meta property="og:title" content="Video Hub | cinewoo" />
        <meta
          property="og:description"
          content={
            "A dynamic collection of behind-the-scenes footage, live shows, live streams, and talent video profiles, sharing personal stories and journeys—all promoting Canadian films and emerging talent."
          }
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/video-hub`}
        />
        <meta property="og:image" content={"https://cinewoo.com/logo.png"} />
        <meta property="og:type" content="Video Hub" />
        <meta property="og:site_name" content="Cinewoo" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/*/--- navbar ---/*/}
      <Navbar />

      {/*/--- banner section ---/*/}
      <section className="home-banner video-hub-banner">
        <div className="video-banner-layer">
          <CContainer className="h-100-p">
            <CRow className="align-items-center h-100-p">
              <CCol md={12}>
                <div className="home-banner-wrapper">
                  <h1 className="typing-demo2">{t("Stay Tuned")}</h1>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </section>

      {/*/--- Footer ---/*/}
      <Footer />

      {/*/--- copy right ---/*/}
      <CopyRight />
    </>
  );
}

export default VideoHub;
