import React, { useState, useEffect } from "react";
import { CContainer, CRow, CCol, CForm, CButton } from "@coreui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { toast } from "react-toastify";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";

//-------- components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [blocking, setBlocking] = useState(false);
  const { t } = useTranslation();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Contact | cinewoo",
    description:
      "Get in touch with Cinewoo for any general or technical inquiries. We're here to help.",
    url: `${window.location.origin}/contact-us`,
    publisher: {
      "@type": "Organization",
      name: "Cinewoo",
      url: "https://cinewoo.com",
      logo: {
        "@type": "ImageObject",
        url: "https://cinewoo.com/logo.png", // Replace with actual logo URL
        width: 250,
        height: 60,
      },
    },
    breadcrumb: {
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Contact Us Page",
          item: `${window.location.origin}/contact-us`,
        },
      ],
    },
  };

  //----- contatact us API integration -----//
  const handleContactUS = async () => {
    if (name === "" || email === "" || subject === "" || description === "") {
      toast.warn(t("Please fill in all required fields!"));
    } else {
      setBlocking(true);
      try {
        const language = localStorage.getItem("language");
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${apiUrl}/contact-us`,
          {
            name: name,
            email: email,
            subject: subject,
            description: description,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "accept-language": language,
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.result === "success") {
          setBlocking(false);
          toast.success(response.data.message);
          setEmail("");
          setSubject("");
          setName("");
          setDescription("");
        } else if (response.data.result === "error") {
          setBlocking(false);
          toast.error(response.data.message);
          // console.log(response.data);
        }
      } catch (error) {
        setBlocking(false);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.message;
          console.error("Error while contact:", errorMessage);
          toast.error(errorMessage);
        } else {
          console.error("Error while contact:", error.message);
          toast.error(error.message);
        }
      }
    }
  };

  return (
    <>
      <BlockUi tag="div" blocking={blocking}>
        {/* <Helmet>
                    <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
                </Helmet> */}

        <Helmet>
          <title>Contact | cinewoo</title>
          <meta
            name="description"
            content="Get in touch with Cinewoo for any general or technical inquiries. We're here to help."
          />

<meta property="og:title" content="Contact | cinewoo" />
          <meta
            property="og:description"
            content="Get in touch with Cinewoo for any general or technical inquiries. We're here to help."
          />
          <meta
            property="og:url"
            content={`${window.location.origin}/contact-us`}
          />
          <meta property="og:image" content={"https://cinewoo.com/logo.png"} />
          <meta property="og:type" content="film pitching" />
          <meta property="og:site_name" content="Cinewoo" />
          <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        </Helmet>
        {/*/--- navbar ---/*/}
        <Navbar />

        {/*/--- banner section ---/*/}
        <section className="home-banner contact-us-banner">
          <CContainer className="h-100-p">
            <CRow className="align-items-center h-100-p">
              <CCol md={12}>
                <div className="home-banner-wrapper text-center">
                  <h2 className="">{t("Contact Us")}</h2>
                  <p>
                    {t(
                      "Any Questions? We're here to help!  Please reach out to us using the form below"
                    )}
                  </p>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </section>

        {/*/--- contact section ---/*/}
        <section className="contact-us-wrapper">
          <CContainer className="h-100-p">
            <CRow className="align-items-center h-100-p">
              <CCol md={12} lg={12}>
                <div className="contact-form-wrapper mt-4 mt-lg-0">
                  <p className="mt-3">
                    {t("Any Questions? We’re Here to Help!")}
                  </p>
                  <CForm className="contact-us-form">
                    <CRow>
                      <CCol lg={6}>
                        <div className="w-100">
                          <input
                            type="text"
                            placeholder={`${t("Name")}*`}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </CCol>
                      <CCol lg={6}>
                        <div className="w-100">
                          <input
                            type="email"
                            placeholder={`${t("Email")}*`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </CCol>
                      <CCol lg={12}>
                        <div className="w-100">
                          <label style={{ marginTop: "30px" }}>
                            {t("Email Subject*")}
                          </label>
                          <select
                            placeholder={`${t("Subject")}`}
                            className="mt-0"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          >
                            <option>{t("Subject")}</option>
                            <option value="General">{t("General")}</option>
                            <option value="Technical Support">
                              {t("Technical Support")}
                            </option>
                            <option value="Other">{t("Other")}</option>
                          </select>
                        </div>
                      </CCol>
                      {/* <CCol lg={6}>
                                                <div className='w-100' style={{marginTop: '35px'}}>
                                                    <Select
                                                        isMulti
                                                        name="roles"
                                                        options={roleOptions}
                                                        className="select-input roles-select-wrapper"
                                                        classNamePrefix="select"
                                                        placeholder={t('Select Roles')}
                                                        onChange={handleRoleChange}
                                                        value={roleOptions.filter(option => roles.includes(option.value))}
                                                    />
                                                </div>
                                            </CCol> */}
                      <CCol lg={12}>
                        <div className="w-100">
                          <textarea
                            rows={6}
                            placeholder={`${t("Description")}*`}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </CCol>
                      <CCol lg={12}>
                        <div className="w-100 mt-5">
                          <CButton
                            className="primary_btn w-100"
                            onClick={handleContactUS}
                          >
                            {t("Submit")}
                          </CButton>
                        </div>
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </section>

        {/*/--- Footer ---/*/}
        <Footer />

        {/*/--- copy right ---/*/}
        <CopyRight />
      </BlockUi>
    </>
  );
}

export default ContactUs;
