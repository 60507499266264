import Routers from './Routers';
import { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';

// styles
import './App.css';
import './assets/css/style.css';
import './assets/css/auth.css';
import './assets/css/responsive.css';
import '@coreui/coreui/dist/css/coreui.min.css';



function App() {
  const [loading, setLoading] = useState(true);
  const recaptchaSiteKey = process.env.REACT_APP_SITE_KEY;

 

  return (
    <>


      <Router>
        <Routers />
      </Router>

      {/* React Toastify */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      {/* ReCAPTCHA */}
      <div className='w-100 d-flex justify-content-center'>
        <ReCAPTCHA sitekey={`${recaptchaSiteKey}`} theme={'dark'} size="invisible" />
      </div>
    </>
  );
}

export default App;
